
































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  wallet = walletStore
  open = false

  close() {
    this.open = false
  }

  login() {
    this.close()
    this.wallet.showConnectWalletDialog(true)
  }
}
